import { onMounted, ref, watch } from '@vue/composition-api'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import category_drug_type_for_search from '@/@fake-db/data/category_drug_type_for_search.json'
import drugGroup from '@/api/DrugManagement/drugGroup'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default function useDrugGroup() {
  const searchtext = ref('')
  const drug_category_id = ref('')
  const statusList = ref(basicStatus.data)
  const categories = ref(category_drug_type_for_search.data)
  const druggroup_status_id = ref(1)

  const dataTableList = ref([])
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'druggroup_id',
      width: 30,
    },
    {
      text: i18n.t('name'),
      value: 'druggroup_name',
      width: 300,
    },
    {
      text: i18n.t('category'),
      align: 'center',
      value: 'druggroup_category_id',
      width: 130,
    },
    {
      text: i18n.t('status'),
      align: 'center',
      value: 'druggroup_status_id',
      width: 120,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 120,
    },
  ])
  const options = ref({})
  const loading = ref(false)

  const processUpdate = ref(false)
  const isUpdateStatus = ref(false)
  const druggroup_id = ref('')
  const druggroup_status_id_update = ref('')

  const { drugGroupList, drugGroupUpdateStatus } = drugGroup

  onMounted(() => {
    fetchDataTable()
  })

  const updateStatus = () => {
    processUpdate.value = true
    drugGroupUpdateStatus({
      druggroup_id: druggroup_id.value,
      druggroup_status_id: druggroup_status_id_update.value == '1' ? '2' : '1',
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      processUpdate.value = false
      isUpdateStatus.value = false
      fetchDataTable()
    })
  }

  const fetchDataTable = () => {
    loading.value = true
    drugGroupList({
      searchtext: searchtext.value,
      drug_category_id: drug_category_id.value,
      druggroup_status_id: druggroup_status_id.value,
    }).then(res => {
      const { data } = res
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    })
  }

  watch([searchtext, drug_category_id, druggroup_status_id], () => {
    fetchDataTable()
  })

  return {
    processUpdate,
    isUpdateStatus,
    druggroup_id,
    druggroup_status_id_update,
    updateStatus,
    searchtext,
    drug_category_id,
    statusList,
    categories,
    druggroup_status_id,
    loading,
    dataTableList,
    options,
    columns,
    fetchDataTable,
  }
}
