<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('Medicine / Equipment Group') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewDrugGroup = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon><span class="d-none d-sm-block"> {{ $t("add_group_drug_equipment") }}</span>
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          icon
          outlined
          @click="isAddNewDrugGroup = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model.trim="drug_category_id"
            :items="categories"
            :label="$t('category')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model.trim="druggroup_status_id"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.druggroup_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.druggroup_category_id`]="{ item }">
          <DrugStatus :type="item.druggroup_category_id" />
        </template>
        <template v-slot:[`item.druggroup_status_id`]="{ item }">
          <StatusBlock :status="item.druggroup_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item;isEditDrugGroup = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.druggroup_active_id=='2'"
                @click="druggroup_id = item.druggroup_id;druggroup_status_id_update = item.druggroup_status_id;isUpdateStatus = true"
                v-on="on"
              >
                <v-icon>{{ item.druggroup_status_id==1? icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.druggroup_status_id==1 ?$t("suspend"):$t('normal') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="isUpdateStatus"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ druggroup_status_id_update ==1?$t('comfirmDrugGroupSuspend'):$t('cancelDrugGroupSuspend') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processUpdate"
            :disabled="processUpdate"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isUpdateStatus = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddNewGroup
      v-model="isAddNewDrugGroup"
      @onAdd="fetchDataTable"
    />
    <EditDrugGroup
      v-model="isEditDrugGroup"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
  </div>
</template>
<script>
import {
  mdiPlus, mdiCheck, mdiDeleteOutline, mdiAlertOutline, mdiPencilOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useDrugGroup from './useDrugGroup'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import DrugStatus from '@/components/basicComponents/DrugStatus.vue'
import AddNewGroup from './AddNewDrugGroup.vue'
import EditDrugGroup from './EditDrugGroup.vue'

export default {
  components: {
    StatusBlock, DrugStatus, AddNewGroup, EditDrugGroup,
  },
  setup() {
    const isAddNewDrugGroup = ref(false)
    const dataEdit = ref({})
    const isEditDrugGroup = ref(false)
    const {
      processUpdate,
      isUpdateStatus,
      druggroup_id,
      druggroup_status_id_update,
      updateStatus,

      searchtext,
      drug_category_id,
      statusList,
      categories,
      druggroup_status_id,
      loading,
      dataTableList,
      options,
      columns,
      fetchDataTable,
    } = useDrugGroup()

    return {
      processUpdate,
      isUpdateStatus,
      druggroup_id,
      druggroup_status_id_update,
      updateStatus,

      dataEdit,
      isEditDrugGroup,
      searchtext,
      drug_category_id,
      statusList,
      categories,
      druggroup_status_id,
      loading,
      dataTableList,
      isAddNewDrugGroup,
      fetchDataTable,
      options,
      columns,
      icons: {
        mdiPlus, mdiCheck, mdiDeleteOutline, mdiAlertOutline, mdiPencilOutline,
      },
    }
  },
}
</script>
