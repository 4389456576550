<template>
  <div>
    <v-dialog
      v-model="isAddNewDrugGroup"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('add_group_drug_equipment') }}
        </v-card-title>
        <v-form
          ref="formAddDrugGroup"
          @submit.prevent="createDrugType"
        >
          <v-card-text>
            <v-text-field
              v-model.trim="clinicName"
              readonly
              :label="$t('clinic_name')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model.trim="druggroup_name"
              class="mt-3"
              autofocus
              :label="$t('type_drug_equipment')"
              :rules="[required]"
              outlined
              dense
            ></v-text-field>
            <v-select
              v-model.trim="druggroup_category_id"
              outlined
              class="mt-3"
              :label="$t('category')"
              dense
              :items="categoryTypeList"
              item-value="value"
              :item-text="$i18n.locale"
            >
            </v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-drug-group', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import category_drug_type from '@/@fake-db/data/category_drug_type.json'
import drugGroup from '@/api/DrugManagement/drugGroup'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewDrugGroup',
    event: 'update:is-add-new-drug-group',
  },

  props: {
    isAddNewDrugGroup: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const formAddDrugGroup = ref(null)
    const categoryTypeList = ref(category_drug_type.data)
    const druggroup_name = ref('')
    const druggroup_category_id = ref(1)
    const clinicName = ref(localStorage.getItem('shop_name'))
    const loading = ref(false)
    const { drugGroupAdd } = drugGroup

    const createDrugType = () => {
      const isFormValid = formAddDrugGroup.value.validate()
      if (!isFormValid) return
      loading.value = true
      drugGroupAdd({
        druggroup_name: druggroup_name.value,
        druggroup_category_id: druggroup_category_id.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        druggroup_name.value = ''
        druggroup_category_id.value = 1
        loading.value = false
        emit('update:is-add-new-drug-group', false)
        emit('onAdd')
      })
    }

    watch(() => props.isAddNewDrugGroup, val => {
      if (val) {
        druggroup_name.value = ''
      }
    })

    return {
      formAddDrugGroup,
      createDrugType,
      required,
      loading,
      clinicName,
      druggroup_category_id,
      druggroup_name,
      categoryTypeList,
    }
  },
}
</script>
